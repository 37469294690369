import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

// TODO: Add react's lazy loading for code splitting
import { Dashboard } from 'pages/Dashboard';
import Login from 'pages/Login';
import { Users } from 'pages/Users';
import { Patient } from 'pages/Patient';
import { Trajectory } from 'pages/Trajectory';
import PasswordReset from 'pages/PasswordReset';
import PasswordResetRequest from 'pages/PasswordResetRequest';
import { selectUser } from 'slices/authenticationSlice';

const NotFound = lazy(() => import('pages/NotFound'));

const Routes: React.FunctionComponent = () => {
    const user = useSelector(selectUser);

    return (
        <>
            {user && (
                <Switch>
                    {['admin', 'technicien', 'manager', 'collaborator'].includes(user.role) && (
                        <Route path="/tableau-de-bord" exact>
                            <Dashboard />
                        </Route>
                    )}
                    {['admin', 'manager'].includes(user.role) && (
                        <Route path="/utilisateurs" exact>
                            <Users />
                        </Route>
                    )}
                    {['admin', 'technicien', 'manager', 'collaborator'].includes(user.role) && (
                        <Route path={user.role === 'collaborator' ? '/' : '/patients'} exact>
                            <Patient />
                        </Route>
                    )}
                    {['admin', 'technicien', 'manager'].includes(user.role) && (
                        <Route path="/" exact>
                            <Trajectory />
                        </Route>
                    )}
                    <Route>
                        <NotFound />
                    </Route>
                </Switch>
            )}
            {!user && (
                <Switch>
                    <Route path={['/mot-de-passe/:token', '/creation-compte/:token']} exact>
                        <PasswordReset />
                    </Route>
                    <Route path="/mot-de-passe-oublie" exact>
                        <PasswordResetRequest />
                    </Route>
                    <Route path="/" exact>
                        <Login />
                    </Route>
                    <Route>
                        <NotFound />
                    </Route>
                </Switch>
            )}
        </>
    );
};

export default Routes;
