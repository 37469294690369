import type { FormEvent } from 'react';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TextField, Button, BodyTwo } from '@osedea/reactor';

import { CenteredContainer } from 'styles/common';
import { authenticateUser } from 'slices/authenticationSlice';
import { LoginCard, Title, LoginError } from './styles';
import type { RootState } from 'store';

interface IProps {}

const Login = (_props: IProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [isSubmitted, setSubmitted] = useState<boolean>(false);

    // error handling from redirection.
    // This is mainly used by request error. Cf. src/utils/request.tsx, request, catch.
    const errorKey = new URLSearchParams(history.location.search).get('errorKey');
    let errorMessage = '';
    switch (errorKey) {
        case 'unauthorized':
            errorMessage = t('general.errorMessage.unautorized');
            break;
        case 'forbidden':
            errorMessage = t('general.errorMessage.forbidden');
            break;
        default:
            errorMessage = t('general.errorMessage.default');
            break;
    }

    // If user is authenticated, redirect to home
    useSelector((state: RootState) => {
        if (state.authentication.user) {
            history.push('/');
        }
    });

    const error = useSelector((state: RootState) => state.authentication.error, shallowEqual);

    const canFormSubmit = () => !loading && email.trim() && password.trim();

    const authHandler = (e: FormEvent) => {
        e.preventDefault();
        if (canFormSubmit()) {
            setLoading(true);
            setSubmitted(true);
            dispatch(authenticateUser(email, password));
        }
    };

    // Reset loading state if errors are returned from api
    useEffect(() => {
        if (error) {
            setLoading(false);
        }
    }, [error]);

    return (
        <>
            <Helmet>
                <title>{t('pages.Login.title')}</title>
            </Helmet>
            <CenteredContainer>
                <form onSubmit={authHandler}>
                    {errorKey && (
                        <LoginError dangerouslySetInnerHTML={{ __html: String(errorMessage) }} />
                    )}
                    <Title>{t('pages.Login.header')}</Title>
                    <LoginCard>
                        <TextField
                            name="email"
                            type="text"
                            label={t('pages.Login.email')}
                            onChange={(e: React.FormEvent) =>
                                setEmail((e.target as HTMLInputElement).value)
                            }
                            value={email}
                        />
                        <TextField
                            name="password"
                            type="password"
                            label={t('pages.Login.password')}
                            onChange={(e: React.FormEvent) =>
                                setPassword((e.target as HTMLInputElement).value)
                            }
                            value={password}
                        />
                        <Button loading={loading} disabled={!canFormSubmit()} onClick={authHandler}>
                            {t('pages.Login.submit')}
                        </Button>
                        <Link to="/mot-de-passe-oublie">
                            <BodyTwo>Mot de passe oublié?</BodyTwo>
                        </Link>
                        {/* TODO: Translate error */}
                        {isSubmitted && error ? (
                            <LoginError dangerouslySetInnerHTML={{ __html: String(error) }} />
                        ) : null}
                    </LoginCard>
                </form>
            </CenteredContainer>
        </>
    );
};

export default Login;
