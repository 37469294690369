import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { selectUser, logoutAuthentication } from 'slices/authenticationSlice';
import { logoutUsers } from 'slices/usersSlice';
import { logoutTrajectory } from 'slices/trajectorySlice';
import { logoutStep } from 'slices/stepSlice';
import { logoutPatient } from 'slices/patientSlice';
import { logoutEntry } from 'slices/entrySlice';
import { logoutUsersTrajectory } from 'slices/usersTrajectorySlice';

import { theme } from 'constants/theme';
// import { LangSelector } from '../LangSelector';

import { MainNavigation, MainNavLink, MainNavButton, UserNavigation } from './styles';

import logo from '../../assets/logo.png';

const Container = styled.header`
    grid-area: header;
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 4px;
    box-shadow: 0px 2px 4px 3px #88888826;
    min-height: 64px;
`;

const Img = styled.img`
    margin-left: 1em;
    width: 52px;
    height: 50px;
    left: 88px;
    top: 7px;
`;

export const Header: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(selectUser);

    const handleLogout = () => {
        dispatch(logoutAuthentication());
        dispatch(logoutUsers());
        dispatch(logoutTrajectory());
        dispatch(logoutStep());
        dispatch(logoutPatient());
        dispatch(logoutEntry());
        dispatch(logoutUsersTrajectory());
        history.push('/');
    };

    const mainNav = (
        <MainNavigation>
            {user && (
                <>
                    <UserNavigation>
                        {['admin', 'technicien', 'manager'].includes(user.role) && (
                            <MainNavLink to="/" exact>
                                {t('pages.Trajectory.title')}
                            </MainNavLink>
                        )}
                        {['admin', 'technicien', 'manager', 'collaborator'].includes(user.role) && (
                            <MainNavLink
                                to={user.role === 'collaborator' ? '/' : '/patients'}
                                exact
                            >
                                {t('pages.Patient.title')}
                            </MainNavLink>
                        )}
                        {['admin', 'technicien', 'manager', 'collaborator'].includes(user.role) && (
                            <MainNavLink to="/tableau-de-bord" exact>
                                {t('pages.Dashboard.title')}
                            </MainNavLink>
                        )}
                        {['admin', 'manager'].includes(user.role) && (
                            <MainNavLink to="/utilisateurs" exact>
                                {t('pages.Users.title')}
                            </MainNavLink>
                        )}
                    </UserNavigation>
                    <MainNavButton onClick={handleLogout}>{t('pages.Logout.title')}</MainNavButton>
                </>
            )}
            {/* {!user && <MainNavLink to="/">{t('pages.Login.title')}</MainNavLink>} */}
        </MainNavigation>
    );

    return (
        <Container>
            <Link to="/">
                <Img alt="chum logo" src={logo} />
            </Link>
            {mainNav}
            {/* <LangSelector /> */}
        </Container>
    );
};
