// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import { GlobalReactorStyles } from '@osedea/reactor';

import App from 'App';
import * as serviceWorker from 'serviceWorker';
import store from 'store';

import 'i18n';

// CSS reset
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';

// Fonts
import RobotoRegularWoff from 'assets/fonts/Roboto-Regular.woff';
import RobotoRegularWoff2 from 'assets/fonts/Roboto-Regular.woff2';
import RobotoMediumWoff from 'assets/fonts/Roboto-Medium.woff';
import RobotoMediumWoff2 from 'assets/fonts/Roboto-Medium.woff2';

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Roboto';
        font-weight: 400;
        src: local('Roboto'), url(${RobotoRegularWoff}) format('woff'), url(${RobotoRegularWoff2}) format('woff2');
    }

    @font-face {
        font-family: 'Roboto';
        font-weight: 500;
        src: local('Roboto'), url(${RobotoMediumWoff}) format('woff'), url(${RobotoMediumWoff2}) format('woff2');
    }

    html, #root {
        height: 100%;
        background-color: 'white';
    }

    body {
        height: 100%;
        margin: 0;
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    input, textarea, select {
        font-family:inherit;
        outline: none;
    }
`;

if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_FRONTEND_DSN,
        integrations: [new BrowserTracing()],
        environment: process.env.REACT_APP_ENVIRONMENT,
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
    });
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <>
                <Router>
                    <Route path="/" component={App} />
                </Router>
                <GlobalStyle />
                <GlobalReactorStyles />
            </>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
